$(document).ready(function(){
    $('.carousel-main-1').slick({
        slidesToShow:1,
        slidesToScroll: 1,
        infinite:false,
        arrows:false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
    });
    
    $('.header .hamburger-icon').click(function(){
        if(!$('.header .hamburger-icon').hasClass('collapsed')){
            $('.header-overlay-js').removeClass('show');
        }else{
            $('.header-overlay-js').addClass('show');
        }
        
    });

    $('.searchform.hidden-xs').click(function(){
        $('.search-overlay').fadeIn();
    });
    $('.search-overlay .closebtn').click(function(){
        $('.search-overlay').fadeOut();
    });
    
});
$(window).on("load", function () {
    scrollDown();
});
$(window).on("scroll", function () {
    scrollDown();
    if($('.section-nearby').length){
        scrollProgress();
    }
});
$(window).resize(function() {
    arrangeDat()
});
function toElm(num){
    $('html').animate({
        scrollTop: $("#shome"+num).offset().top -100
    }, 2000);
}
function toCont(num){
    $('html').animate({
        scrollTop: $("#content"+num).offset().top -110
    }, 1000);
}
function scrollDown() {
    if ($(window).scrollTop() > 1) {
        $(".section-header-js").addClass("active")
    } else {
        $(".section-header-js").removeClass("active")
    }
}
function scrollProgress() {
    var element_position = $('.section-nearby').offset().top;
    var positionscroll = $(window).scrollTop()+$(window).height()/2;
    if (positionscroll > element_position) {
        arrangeDat();
    } else {
        $(".section-nearby .progress-label").removeAttr('style');
        $(".section-nearby .progress-bar").removeAttr('style');
    }
}
$(function(){
    $('#submitFormAll').click(function(){
        var result = validateForm();
        if(result){
            console.log('submit')
        }
    });
});

function arrangeDat(){
    $(".section-nearby .b-left .progress-label").each(function(index) {
        var dat = $(this).data("pvalue");
        $(".section-nearby .b-left .progress-label").eq(index).css('left',dat+'%');
    });
    $(".section-nearby .b-right .progress-label").each(function(index) {
        var dat = $(this).data("pvalue");
        var width = $(window).width();
        $(".section-nearby .b-right .progress-label").eq(index).removeAttr('style');
        if (width > 768) {
            $(".section-nearby .b-right .progress-label").eq(index).css('right',dat+'%');
        }else{
            $(".section-nearby .b-right .progress-label").eq(index).css('left',dat+'%');
        }
    });
    $(".section-nearby .progress-label").each(function(index) {
        var dat = $(this).data("pvalue");
        var width = $(window).width();
        $(".section-nearby .progresswrap").eq(index).find('.progress-bar').css('width',dat+'%');
    });
}
function validationnull(dat){
    if(dat==""||dat=="undefined"||dat==null){
        return true;
    }
    else{
        return false;
    }
}
function validateEmail(email) {
    var re = /^[a-zA-Z0-9.!#$%&'*+\/=?\^_'{\}~\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(email);
}
function validatephone(phone) {
    var re = /^(0)(8|9|6|2)[0-9]{7}([0-9]{1})?$/;
    return re.test(phone);
}
function validateForm(){
    $('.fieldwrap').find('.errortext').remove();
    var chk = true;

    //Check input is null or empty
    $('.checknull').each(function () {
        var str = $(this).find('input[type=text]').val();
        if(validationnull(str)){
            $(this).find('.errortext').remove();
            $(this).append('<div class="errortext">กรุณากรอกข้อมูลของคุณ</div>');
            chk = false;
        }
    });
    //Check input is invalid Email
    $('.checkmail').each(function () {
        var str = $(this).find('input[type=text]').val();
        if (!validateEmail(str)) {
            $(this).find('.errortext').remove();
            $(this).append('<div class="errortext">กรุณากรอก E-mail ของคุณให้ถูกต้อง</div>');
            chk = false;
        }
    });
    //Check input is invalid Phone
    $('.checkphone').each(function () {
        var str = $(this).find('input[type=text]').val();
        if (!validatephone(str)) {
            $(this).find('.errortext').remove();
            $(this).append('<div class="errortext">กรุณากรอกเบอร์โทรของคุณให้ถูกต้อง</div>');
            chk = false;
        }
    });
    return chk;
}